:root{--primary-color: #5b4ae4;--primary-color-dark: #3f2c91;--secondary-color: #cb4d94;--secondary-color-dark: #a02e6f;--accent-color: #03b4ff;--cta-color: var(--yellow);--background-color: #15141f;--surface-color: #201e2f;--text-color: #fbfbff;--border-color: #35298f;--border: 1px solid var(--border-color);--gradient-background: linear-gradient(90deg, #7700e7 0%, #d50293 100%) 50% 50% / cover no-repeat scroll;--top-menu-height: 70px;--lecture-footer-height: 72px;--max-content-height: calc(100vh - var(--top-menu-height) - var(--lecture-footer-height));--max-content-height-without-footer: calc(100vh - var(--top-menu-height));--sidebar-width: 500px;--code-background-color: rgb(40, 42, 54);--yellow: #ffd300;--red: rgb(220, 53, 69);--green: #56bd5b;--gray: #636d6d}html body{margin:0;font-weight:400;font-size:1.125rem;line-height:1.5;background-color:var(--background-color) !important;color:var(--text-color) !important}*{box-sizing:border-box}input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{-webkit-box-shadow:0 0 0 30px var(--surface-color) inset !important;-webkit-text-fill-color:#ddd !important;background-color:rgba(0,0,0,0) !important}::-webkit-scrollbar{width:.5em;height:.5em}::-webkit-scrollbar-track{box-shadow:inset 0 0 3px var(--border-color);border-radius:.5em}::-webkit-scrollbar-thumb{border-radius:.5em;background-color:var(--surface-color);outline:1px solid var(--primary-color)}h1{font-weight:bold;font-size:3rem;line-height:1.25}h2{font-weight:bold;font-size:2.5rem;line-height:1.25}h3{font-weight:bold;font-size:2rem;line-height:1.25}h4{font-size:1.75rem;line-height:1.25}textarea{background:rgba(0,0,0,0)}p{margin:0}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/01b6d16db7cbd96b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d9e386ae70efc2f0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d0f96be320385a33-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c898cbfd2f789a8c-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b9472d49e3bc18c3-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b5c08a795ae281ca-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2744aa005c8cf586-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e4f65e35dde2bee1-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6194a65a1b989dc8-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/418bb9d724f84584-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/025c5221126e4e89-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6a5d8dc148518b96-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e7814bd1d06a39b6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7f5a4bbe7ec7be95-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/483de911b1a0d258-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0e5e1c6a8db9e432-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5693677ef07d9b51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28aa5118b38b86e4-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8720059dfa14a1fe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df6186c8d69644-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/120a5a1920781bd0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/80b1a0e600ca6d83-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7c16c8204ab29534-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f756da832d8c34d4-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/98a28a5430a3cf7f-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df2942b6de9d14b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7a7012758df5a81e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9591a3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6ebb97b5c9fa4e03-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_9591a3';src: local("Arial");ascent-override: 92.98%;descent-override: 24.47%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_9591a3 {font-family: '__Roboto_9591a3', '__Roboto_Fallback_9591a3'
}

