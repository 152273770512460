:root {
  --primary-color: #5b4ae4;
  --primary-color-dark: #3f2c91;
  --secondary-color: #cb4d94;
  --secondary-color-dark: #a02e6f;
  --accent-color: #03b4ff;
  --cta-color: var(--yellow);

  --background-color: #15141f;
  --surface-color: #201e2f;

  --text-color: #fbfbff;

  --border-color: #35298f;
  --border: 1px solid var(--border-color);

  --gradient-background: linear-gradient(90deg, #7700e7 0%, #d50293 100%) 50% 50% / cover no-repeat scroll;

  --top-menu-height: 70px;
  --lecture-footer-height: 72px;
  --max-content-height: calc(100vh - var(--top-menu-height) - var(--lecture-footer-height));
  --max-content-height-without-footer: calc(100vh - var(--top-menu-height));

  --sidebar-width: 500px;
  --code-background-color: rgb(40, 42, 54);

  --yellow: #ffd300;
  --red: rgb(220, 53, 69);
  --green: #56bd5b;
  --gray: #636d6d;
}

html body {
  margin: 0;

  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;

  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

* {
  box-sizing: border-box;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--surface-color) inset !important;
  -webkit-text-fill-color: #ddd !important;
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--border-color);
  border-radius: 0.5em;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  background-color: var(--surface-color);
  outline: 1px solid var(--primary-color);
}

h1 {
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.25;
}

h2 {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.25;
}

h3 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.25;
}

h4 {
  font-size: 1.75rem;
  line-height: 1.25;
}

textarea {
  background: transparent;
}

p {
  margin: 0;
}